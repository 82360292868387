import { DEMO } from "utils/app/appConstants";
import {
  SingleText,
  Countries,
  Interest,
  CustomChoice,
} from "./Wizard/FormConent/index";
import { Reference } from "./Wizard/FormConent/Reference";
import { ALPACA_URL } from "services/alpaca/constants";

export function renderFormContent(
  stageKey,
  onBoardingState,
  handleFormChange,
  description,
  interests,
  data_cy,
  references
) {
  switch (stageKey) {
    case "name":
      return (
        <SingleText
          data_cy={data_cy}
          placeholder={"Escribe tu nombre..."}
          value={onBoardingState[stageKey]}
          description={description}
          handleOnChange={handleFormChange}
        />
      );
    case "tenant_name":
      return (
        <SingleText
          data_cy={data_cy}
          placeholder={"El nombre de tu empresa..."}
          value={onBoardingState[stageKey]}
          helperLink
          description={description}
          handleOnChange={handleFormChange}
        />
      );
    case "reference":
      return (
        <Reference
          value={onBoardingState[stageKey]}
          references={references}
          description={description}
          handleOnChange={handleFormChange}
        />
      );
    case "country":
      return (
        <Countries
          data_cy={data_cy}
          value={onBoardingState[stageKey]}
          description={description}
          handleOnChange={handleFormChange}
        />
      );
    case "flow":
      if (onBoardingState?.custom_shoice === DEMO)
        return (
          <CustomChoice
            data_cy={"onboarding-no-connect-view"}
            title={"Explora el demo de la aplicación"}
            subtitle={`Si prefieres no conectar tus marketplaces de inmediato, te invitamos a conocer el 
            <a href="https://demo.wivoanalytics.com" target="_blank" style={{ cursor: "pointer"}}>DEMO</a> de nuestra aplicación.`}
          />
        );
      else
        return (
          <Interest
            data_cy={data_cy}
            value={onBoardingState[stageKey]}
            interests={interests}
            description={description}
            handleOnChange={handleFormChange}
          />
        );
    default:
      break;
  }
}

// Esta función redirige al usuario a la URL de registro de Shopify en Alpaca
export const redirectToFinishShopifyIntegration = (shopifyParams, tenant) => {
  // Si no existe el tenant no se hace nada
  if (!tenant) return;

  // Extraer el tenant de la URL
  const { origin, href } = window.location;
  const [, fullPath] = href.split(origin);
  const [activeUser, hash] = fullPath.split("/#/");
  const current_tenant = activeUser.split("/");

  // Creando query params para la URL de redirección
  const currentTenant = `current_tenant=${current_tenant.at(-1)}`;

  // Construyendo la URL de redirección
  const url = `${ALPACA_URL}/account/signup/shopify/register_new`;
  const params = new URLSearchParams(shopifyParams).toString();
  const fullURL = url?.concat(`?${params}&tenant=${tenant}&${currentTenant}`);

  // Limpiando los parametros de shopify del sessionStorage
  localStorage.removeItem("shopifyParams");

  // Redirigiendo a la URL de redirección
  window.open(fullURL, "_self");
};

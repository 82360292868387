import { useEffect } from "react";

import { hashHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

import AppLoading from "components/Progress/AppLoading";
import { isShopifyParams } from "utils/utils";

const isDemo = process.env.REACT_APP_DEMO?.toLowerCase() === "true";

const Auth0Signup = ({ shopifyParams }) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  // Almacenando los parámetros en sessionStorage (como respaldo)
  if (shopifyParams && isShopifyParams(shopifyParams)) {
    // Verificamos si los parámetros ya existen en localStorage
    const shopifyParamsInLocalStorage = localStorage.getItem("shopifyParams");
    if (shopifyParamsInLocalStorage === null) {
      // Si no existe, guardamos los parámetros en localStorage
      localStorage.setItem("shopifyParams", JSON.stringify(shopifyParams));
    }
  }

  const signup = () => {
    return loginWithRedirect({ screen_hint: "signup" });
  };

  useEffect(() => {
    async function checkUser() {
      if (isAuthenticated) {
        await hashHistory.push("/home");
      } else {
        signup();
      }
    }
    if (!isDemo) checkUser(); // called async checkUser()
  }, [isAuthenticated, loginWithRedirect]);

  if (isDemo) {
    hashHistory.push("/home");
    return null;
  }

  return <AppLoading text="Cargando..." />;
};

export default Auth0Signup;

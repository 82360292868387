const styles = {
  root: {
    height: "100%",
    width: "100%",
    background: "#fff",
  },
  rightPanelParent: {
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  rightPanelRoot: {
    height: "90%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  rightPanelForm: {
    height: "80vh",
    overflow: "auto",
    padding: "0 2rem",
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  formRoot: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "850px",
    width: "100%",
    alignContent: "flex-start",
    maxHeight: "100%",
  },
  formTitle: {
    fontFamily: "IBM Plex Sans",
    //fontSize: "1.3rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "var(--blue-gray-5)",
    margin: "0.1% 0",
  },
  formtext: {
    maxWidth: "25em",
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem",
    },
  },
  formActionRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 5,
  },
  joinTeam: {
    ":hover": { textDecoration: "underline" },
    color: "#3770EF",
    fontSize: "0.9rem",
    cursor: "pointer",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
  },
  interestFormLabel: {
    fontSize: "1.2rem",
    fontWeight: " 500",
    color: "#5B717B",
  },
  interestFormHelper: {
    fontSize: "0.9rem",
    marginLeft: "2rem",
    marginTop: "-0.5rem",
  },
  radioButton: {
    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
      color: "#3770ef",
    },
  },
  fullScreen: { display: "flex", justifyContent: "center" },
};

export default styles;

export const DATA_CY = {
  HOME: {
    mainContent: "home-root",
    keywords: "keywords",
    publications: "publications",
    orders: "orders",
    dashboard: "dashboard",
    dashboard_profit: "dashboard-profit",
    reports: "reports",
    benchmark: "benchmark",
    integration: "integration",
  },
  KEYWORDS: {
    mainContent: "keywords-list",
  },
  PUBLICATIONS: {
    mainContent: "publications-list",
    mainContentDetails: "publications-details",
    productCost: "publications-product-cost",
    thresholdsButton: "publications-conf",
  },
  ORDERS: {
    mainContent: "orders-list",
    mainContentDetails: "orders-details",
  },
  DASHBOARD: {
    mainContent: "dashboard-sales",
  },
  PROFIT: {
    mainContent: "dashboard-profit",
    productCost: "profit-prodcut-cost",
    profitConf: "profit-conf",
  },
  REPORTS: {
    mainContent: "reports-list",
  },
  INTEGRATIONS: {
    mainContent: "integrations-content",
  },
  COMPONENTS: {
    date_select: "calendar-selector",
    date_range_picker: "date_range_picker",
    date_range_picker_apply: "date_range_picker_apply",
    search_input: "search_input",
    search_input_clear: "search_input_clear",
    search_input_apply: "search_input_apply",
    unprofitable: "Poco rentable",
    highShippingCost: "Costo de envío alto",
    highCommissions: "Comisiones altas",
    lowProfitability: "Baja rentabilidad",
    negativeProfitability: "Rentabilidad Negativa",
    godProfitability: "Buena rentabilidad",
    justPaid: "Recién pagadas",
    unitsSimple: "1 Unidad",
    moreUnits: "2 Unidades o más",
    noPayments30d: "Sin pagos en +30d",
    paymentStatusAvailable: "Con Pagos",
    paidPaymentStatus: "Pagadas",
    unpaidPaymentStatus: "No Pagadas",
    noinformationPaymentStatus: "Sin información",
    unknownpaymentstatusPaymentStatus: "Desconocido",
    regularItemStatus: "Regular",
    returnedItemStatus: "Devuelta",
    canceledItemStatus: "Cancelada",
    fullfilmentLogisticStatus: "Fulfillment",
    noFullfilmentLogisticStatus: "No Fulfillment",
    unknowLogisticStatus: "Desconocido",
    flex: "Flex",
    noInformationLogisticStatus: "Sin Información",
    marketplaces: "marketplaces",
    moreFilters: "moreFilters",
    filtersButton: "filters-button",
    filtersRoot: "filters-root",
    filtersTabs: "filters-tabs",
    filtersApply: "filters-apply",
    allCategory: "Todos",
    list: "nested-list-subheader",
    navHome: "nav-home",
    navKeywords: "nav-keywords",
    navPublications: "nav-publications",
    navOrders: "nav-orders",
    navDashboard: "nav-dashboard",
    navDashboardSales: "nav-dashboardSales",
    navDashboardProfit: "nav-dashboardProfit",
    navReports: "nav-reports",
    navAlerts: "nav-alerts",
    benchmark: "nav-benchmark",
    navIntegrations: "nav-integrations",
    goBack: "go-back",
    metrics: "metrics",
    compareTo: "compare-to",
    basicTable: "basic-table",
    downloadButton: "download-button",
  },
};

import {
  FormControl,
  FormControlLabel,
  Grow,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOnBoardingData } from "redux/OnBoarding/actions";
import { COLORS } from "utils/app/appConstants";

export function Reference(props) {
  const { value, references = {}, handleOnChange } = props;
  const [referencer, setReferenceddBy] = useState(null);
  const onBoardingState = useSelector((state) => state.get("onBoarding"));
  const [state, setState] = useState("");
  const dispatch = useDispatch();
  const referenceRef = useRef(null);

  const { referenced_by } = onBoardingState;

  useEffect(() => {
    if (value) {
      setState(value);
      handleOnChange({ target: { value: value } });
    }
  }, [value]);

  useEffect(() => {
    if (referenced_by) {
      setReferenceddBy(referenced_by);
    }
  }, [referenced_by]);

  const handleChange = (event) => {
    const { value } = event.target;
    setState(value);
    handleOnChange(event);
    setReferenceddBy(null);
    dispatch(updateOnBoardingData({ referenced_by: null }));
  };

  const handleTextFieldChange = (event) => {
    const { value } = event.target;
    if (value) {
      setReferenceddBy(value);
      dispatch(updateOnBoardingData({ referenced_by: value }));
    }
  };

  return (
    <FormControl sx={{ mt: 2 }}>
      <RadioGroup
        name="reference-buttons-group"
        onChange={handleChange}
        value={state}
        ref={referenceRef}
      >
        {Object.entries(references).map(([keyValue, value]) => (
          <div
            key={keyValue}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControlLabel
              value={keyValue}
              key={keyValue}
              control={
                <Radio size="small" style={{ color: COLORS.BLUE_BASE }} />
              }
              label={value?.label}
              sx={{ "& .MuiTypography-root": { fontFamily: "IBM Plex Sans" } }}
            />
            {state === "recommender" && keyValue === "recommender" ? (
              <Grow in={true}>
                <TextField
                  sx={{ ml: 3 }}
                  value={referencer || ""}
                  onChange={handleTextFieldChange}
                  size="small"
                  inputProps={{ style: { color: "var(--blue-gray-5)" } }}
                  label="¿Quién?"
                />
              </Grow>
            ) : null}
          </div>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

import Livez from "components/Livez/Livez";
import Auth0Login from "containers/Login/Auth0Login";
import Auth0Signup from "containers/Login/Auth0Signup";
import App from "./App";
import { VerifyEmail } from "components/VerifyEmail/VerifyEmail";

const AppMiddleware = (props) => {
  // Extraer los parámetros de la URL
  const path = window.location;

  // Extraer el pathname y los parámetros de búsqueda
  const { pathname } = path;

  // Extraer los parámetros de búsqueda de la URL
  const params = path.href.split("?")[1];

  // Crear un objeto URLSearchParams a partir de los parámetros de búsqueda
  const searchParams = new URLSearchParams(params?.split("#/")[0]);

  // Convertir los parámetros de búsqueda en un objeto
  const queryParams = Object.fromEntries(searchParams.entries());

  console.log("URL PARAMS", {
    path,
    pathname,
    params,
    searchParams,
    queryParams,
  });

  // Verificar si la URL es un a consulta de salud de la app
  if (path.hash === "#/livez") return <Livez />;

  // Redireccionar al login de Auth0
  if (path.hash === "#/login" || pathname === "/login")
    return <Auth0Login shopifyParams={queryParams} />;

  // Redireccionar al signup de Auth0
  if (path.hash === "#/signup" || pathname === "/signup")
    return <Auth0Signup shopifyParams={queryParams} />;

  // Redireccionar a la vista de espera de verificación de email
  // esta vista es bloquante y no permite navegar a otras vistas
  // si el usuario no ha verificado su email
  if (path.hash.includes("#/verify-email")) return <VerifyEmail />;

  // Redireccionar a la app
  return <App {...props} />;
};

export default AppMiddleware;

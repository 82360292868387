import WivoButton from "components/WivoButton/Button";
import { hashHistory } from "react-router";

export function VerifyEmail() {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: "2rem",
        backgroundColor: "#f9f9f9",
      }}
    >
      <img
        src="/images/logo.svg"
        alt="Logo Wivo Analytics"
        width={90}
        height={90}
        style={{ marginBottom: "1rem" }}
      />
      <h1 style={{ marginBottom: "1rem", fontSize: "2rem" }}>
        Bienvenido a Wivo!
      </h1>
      <p
        style={{
          textAlign: "center",
          maxWidth: "600px",
          lineHeight: "1.5",
          fontSize: "1.1rem",
          color: "#555",
        }}
      >
        Gracias por registrarte. Hemos enviado un enlace de verificación a tu
        correo electrónico. Por favor, revisa tu bandeja de entrada y sigue las
        instrucciones para activar tu cuenta.
      </p>
      <WivoButton
        sx={{ marginTop: "2rem" }}
        color="primary"
        label="He verificado mi correo"
        handleOnClick={() => {
          hashHistory.push("/home");
        }}
      />
      {/*<WivoButton
        text
        sx={{ marginTop: "1rem" }}
        label="salir"
        handleOnClick={() => {
          logout({ returnTo: LOGOUT_REDIRECT });
        }}
      />*/}
    </section>
  );
}

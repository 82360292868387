import React, { useEffect } from "react";

import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import styles from "../../styles";

const countryItem = ({ key, countryFlag, label }) => (
  <div
    key={key}
    style={{ display: "flex", direction: "row", alignItems: "center" }}
  >
    <img src={countryFlag} alt={label} height={"15em"} />

    <Divider orientation="vertical" flexItem sx={{ ml: 1.5, mr: 1.5 }} />
    <span>{label}</span>
  </div>
);
const countries = [
  { key: "CL", label: "Chile", countryFlag: "/images/countries/chile.webp" },
  { key: "PE", label: "Perú", countryFlag: "/images/countries/peru.webp" },
  {
    key: "CO",
    label: "Colombia",
    countryFlag: "/images/countries/colombia.webp",
  },
  { key: "MEX", label: "México", countryFlag: "/images/countries/mexico.webp" },
  {
    key: "AR",
    label: "Argentina",
    countryFlag: "/images/countries/argentina.webp",
  },
  {
    key: "UY",
    label: "Uruguay",
    countryFlag: "/images/countries/uruguay.webp",
  },
];
export function Countries(props) {
  const { value, description, handleOnChange, data_cy } = props;
  const [state, setState] = React.useState("");

  useEffect(() => {
    if (value) {
      setState(value);
      handleOnChange({ target: { value: value } });
    } else {
      setState("CL");
      handleOnChange({ target: { value: "CL" } });
    }
  }, [value]);

  const handleChange = (event) => {
    const { value } = event.target;
    setState(value);
    handleOnChange(event);
  };

  return (
    <FormControl
      sx={styles.formtext}
      fullWidth
      variant="outlined"
      margin="dense"
      data-cy={data_cy}
    >
      <InputLabel htmlFor="outlined-age-native-simple">
        Seleccione el país
      </InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        style={{ color: "#5B717B" }}
        value={state}
        onChange={handleChange}
        label="Seleccione el pais"
        size="small"
        inputProps={{
          name: "country",
          id: "outlined-age-native-simple",
        }}
        data-cy={"onboarding-country-select"}
      >
        {countries.map((country) => (
          <MenuItem key={country.key} value={country.key} data-cy={country.key}>
            {countryItem(country)}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>
        <span style={{ marginTop: "1em" }} className="body1">
          {description}
        </span>
      </FormHelperText>
    </FormControl>
  );
}

import React from "react";

import { Grid } from "@mui/material";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

export function Interest(props) {
  const { interests, data_cy } = props;

  return (
    <Grid container direction="column" spacing={2} data-cy={data_cy}>
      <Grid item>
        <p className="body2">{"Una vez que la conectes podrás:"}</p>
      </Grid>
      <Grid item>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {Object.entries(interests).map(([key, value]) => (
            <div
              key={key}
              style={{
                display: "flex",
                columnGap: "1em",
              }}
            >
              <p>
                <CheckCircleOutline
                  style={{ color: " #3770EF", marginTop: 0 }}
                />
              </p>
              <p
                className="body2"
                style={{ /* fontWeight: 500, */ maxWidth: "580px" }}
              >
                {value.label}
              </p>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

import moment from "moment";
import jwt_decode from "jwt-decode";

import { printKodkodLog, registerIdentify } from "utils/utils";
import { getAccountData } from "services/alpaca/request";
import { registerDevice } from "services/almiqui/auth0/request";
import { updateOnBoardingData } from "redux/OnBoarding/actions";
import { updateUserIdentity } from "redux/UserIdentity/actions";

const claims = process.env.REACT_APP_CLAIMS;

export function saveIdentifyData(data) {
  const interest = {
    4: "cancellations",
    5: "payments",
    6: "marketplaces",
    7: "sales",
  };

  const { userData, isDemo } = data;
  const IS_DEMO = isDemo.demoEnabled;

  let now = moment(new Date()); //current date
  let signupDate = moment(userData?.user_created_at); // user user_created_at date
  let antiguedad = now.diff(signupDate, "days");

  const interests = userData?.user_metadata?.interests;
  const flow = userData?.user_metadata?.flow;
  const home_dashboard = userData?.user_metadata?.home_dashboard;
  const recommender = userData?.user_metadata?.recommender;

  const defaultData = {
    user_id: userData?.sub, //id de usuario desde auth0 //cambiar a user_id
    user_name: userData?.email, // camiar a user_name
    email: userData?.email,
    "Cantidad de logins": userData?.loginsCount, //cantidad de login
    value_proposition_preference:
      interests || (home_dashboard && [interest[home_dashboard]]),
    "Días de antiguedad": antiguedad, //contar los dias de antiguedad
    flow: flow, //flujo escogido durante el onboarding connect/disconnect
    ecommerce_app: true,
    recommender,
  };

  const identifyData = {
    ...defaultData,
    ...(IS_DEMO ? { demo_awareness: true } : {}),
  };

  registerIdentify(userData?.sub, identifyData);
}

export const getUserDataState = async (user, token) => {
  const decoded = jwt_decode(token);
  const { tenant } = decoded?.[claims] || {};
  const tenantsListTest =
    tenant?.map(async (t) => {
      return getAccountData(t, true).then((resp) => {
        const { err, data, status } = resp;
        if (err) return undefined;
        if (status >= 200 && status <= 300) return data;
        return undefined;
      });
    }) ?? [];
  const test = await Promise.allSettled(tenantsListTest);
  return {
    ...user,
    ...(decoded?.[claims] || {}),
    tenant: tenant ? tenant[0] : undefined,
    tenantsList: test?.map((item) => item.value).filter((ten) => ten) ?? [], //tenant,
  };
};

export const getAccountDataState = (error, tenant, accountData) => {
  if (error) {
    return {
      tenant: tenant,
      client: "",
      country: "",
      properties: {
        plan: "",
        tenant_status: "ENABLED",
        compare_mode: "standar",
      },
    };
  } else {
    return {
      ...accountData,
      client: accountData?.slug_name,
      compare_mode: accountData?.compare_mode || "standar",
    };
  }
};

export const getLastUpdatedApp = () => {
  return fetch(`${window.location.origin}/manifest.json`)
    .then((resp) => {
      return resp.headers.get("Last-Modified");
    })
    .catch((err) =>
      printKodkodLog(
        "newVersionAvailable Error al comprobar si existe una actualización disponible",
        err
      )
    );
};

export const isNewerVersionAvailable = async () => {
  let appLastUpdated = localStorage.getItem("last-updated");
  const appVersion = await getLastUpdatedApp();
  if (appLastUpdated === null) {
    appLastUpdated = appVersion;
    localStorage.setItem("last-updated", appVersion);
  }
  if (appLastUpdated !== appVersion) {
    return { isNewVersion: true, modified: appVersion };
  }
  return { isNewVersion: false, modified: undefined };
};

export const getTenantAddons = (properties) => {
  if (!properties?.addons) return {};
  let addons = {};
  properties.addons.forEach((addon) => {
    addons[addon.type] = addon;
  });
  return addons;
};

export const QUICK_FILTERS = {
  "Poco rentable": true,
  "Baja rentabilidad": true,
  "Rentabilidad Negativa": true,
  "Buena rentabilidad": true,
};

export const snackbarConfigurations = (range) => {
  return {
    loading: {
      variant: "snackbar",
      type: "loader",
      text: `Estamos preparando tu planilla, este proceso puede tomar ${
        range ? `hasta ${range}` : `varios`
      } minuto(s)...`,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      persist: true,
    },
    error: {
      variant: "snackbar",
      type: "error",
      title: "Error",
      text: "Error al generar la planilla.",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    },
    completed: {
      variant: "snackbar",
      type: "success",
      title: "Planilla lista",
      text: "La planilla se ha generado satisfactoriamente, en breve iniciará la descarga.",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    },
  };
};

export const snackbarFillCostConf = () => {
  return {
    loading: {
      variant: "snackbar",
      type: "loader",
      text: `Estamos procesando tu planilla, este proceso puede tomar varios minutos...`,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      persist: true,
    },
    error: {
      variant: "snackbar",
      type: "error",
      title: "Error",
      text: "Error al procesar la planilla.",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    },
    completed: {
      variant: "snackbar",
      type: "success",
      title: "Planilla procesada",
      text: "Los costos se han actualizado satisfactoriamente, en breve se reflejarán en la plataforma.",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    },
  };
};

// Manejando nueva integración de Shopify desde shopify app store
export const handleNewShopifyIntegration = (params = {}, dispatch) => {
  // Inicializando shop
  const { shop = "" } = params;

  // Sugiriendo un tenant_name en base al shop name en shopify
  const tenant_name = shop?.split(".")[0] || null;

  // Sugiriendo un tenant_name en base al shop name en shopify
  dispatch(updateOnBoardingData({ tenant_name }));

  // Guardando los parametros de la integración de shopify en la identidad del usuario
  dispatch(updateUserIdentity({ shopifyParams: { ...params } }));
};

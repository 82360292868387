import { useState, useEffect } from "react";

import { TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { localization } from "localization/es";
import { uniqueId } from "utils/utils";
import { updateOnBoardingData } from "redux/OnBoarding/actions";
import { CustomChoice } from "./index";
import styles from "../../styles";
import { HAS_TEAM } from "utils/app/appConstants";

export function SingleText({
  value,
  description,
  helperLink,
  handleOnChange,
  placeholder,
  data_cy,
}) {
  const [customChoice, setChoise] = useState(undefined);
  const onBoardingState = useSelector((state) => state.get("onBoarding"));
  const dispatch = useDispatch();

  const { custom_shoice } = onBoardingState;
  const {
    ONBOARDING: { JOIN_TEAM, JOIN_REQUEST },
  } = localization;

  useEffect(() => {
    setChoise(custom_shoice);
  }, [custom_shoice]);

  const hanldeCustomChoice = (choise) => {
    dispatch(updateOnBoardingData({ custom_shoice: choise }));
    setChoise(choise);
  };

  if (customChoice)
    return (
      <CustomChoice
        data_cy={"onboarding-join-team"}
        title={JOIN_TEAM}
        subtitle={JOIN_REQUEST}
      />
    );

  return (
    <>
      <TextField
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            dispatch(updateOnBoardingData({ keyAction: "NEXT" }));
          }
        }}
        autoFocus
        id={uniqueId()}
        value={value || ""}
        placeholder={placeholder}
        helperText={
          <span style={{ marginTop: "1em" }} className="body1">
            {description}
          </span>
        }
        sx={styles.formtext}
        fullWidth
        margin="normal"
        onChange={handleOnChange}
        inputProps={{
          "data-cy": data_cy,
          style: {
            color: "#3770EF",
            fontSize: "1.6em",
          },
        }}
        variant="standard"
      />
      {helperLink && (
        <Typography
          style={{ marginTop: "2rem" }}
          className="body2"
          sx={styles.joinTeam}
          onClick={() => hanldeCustomChoice(HAS_TEAM)}
          data-cy="onboarding-join-team-option"
        >
          {JOIN_TEAM}
        </Typography>
      )}
    </>
  );
}

import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadIntegratedAccounts } from "redux/IntegratedAccounts/actions";

import {
  loadIntegrationData,
  updateIntegrationData,
} from "redux/IntegrationsStatus/actions";
import { getIntegrationData, getSources } from "services/alpaca/request";
import { BASIC_SOURCE_DATA } from "containers/Marketplaces/sources/sourcesList";
import { RECOMMENDED_MARKETS_BY_COUNTRY } from "utils/app/appConstants";
import { printKodkodLog } from "utils/utils";

export function useIntegrationData() {
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const integrationsStatus = useSelector((state) =>
    state.get("integrationsStatus")
  );
  const tenant = userIdentity?.accountData?.tenant;
  const country = userIdentity?.accountData?.country;

  const [error, setError] = useState("");
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  const [integrations, setIntegrations] = useState({});
  const [sourceError, setSourceError] = useState(0);
  const dispatch = useDispatch();

  const initIntegrationData = async (tenant, from, notRelaod = false) => {
    if (tenant) {
      await marketplaceIntegrationData({ tenant, from });
      integratedAccounts(tenant, notRelaod);
    }
  };

  useEffect(() => {
    if (tenant) {
    }
  }, [tenant]);

  const marketplaceIntegrationData = ({
    tenant,
    from = "onboarding=false",
  }) => {
    return getIntegrationData(tenant, from)
      .then((resp) => {
        const { data, status, err } = resp;
        if (status >= 200 && status < 300) {
          const sources = Object.entries(data).filter(
            ([key, value]) => key.includes("has_") && value === true
          );
          dispatch(
            loadIntegrationData({
              ...integrationsStatus,
              ...data,
              hasAnyIntegration: sources.length ? true : false,
              integrationsCount: sources.length,
            })
          );
        }
        if (err) printKodkodLog(err);
      })
      .catch((error) => {
        printKodkodLog(error);
      });
  };

  const integratedAccounts = (tenant, notRelaod = false) => {
    !notRelaod && setLoadingIntegration(true);
    return getSources(tenant)
      .then((resp) => {
        const { data, status, err } = resp;
        if (status >= 200 && status < 300) {
          let result = 0;
          Object.values(data).forEach((source) => {
            source.forEach((item) => {
              if (
                item.source_status === "BAD_CREDENTIALS" ||
                item.source_status === "UPDATE_TOKEN" ||
                item.source_status === "WITH_OUT_CREDENTIALS"
              )
                result++;
            });
          });
          setSourceError(result);

          const mixData = {};
          const sourcesIntegrated = [];
          const recomended = RECOMMENDED_MARKETS_BY_COUNTRY[country];
          let allRecommendedIntegrationAvailablOK = false;
          let recommendedIntegrationAvailableOK = false;
          let hasAnySourcesOK = false;
          let hasSourcesWithOutCredentials = false;
          let sourcesWithOutCredentials = undefined;
          let sourcesRecommendedWithCredentials = undefined;
          let hasAnySourceRecommendedWithCredentials = false;
          Object.entries(data).forEach(([sourceKey, accounts]) => {
            mixData[sourceKey] = {
              ...BASIC_SOURCE_DATA[sourceKey],
              accounts,
            };
            if (accounts.length) {
              sourcesIntegrated.push(sourceKey);
              const isRecomended = recomended.indexOf(sourceKey);
              const sourcesWithCredentials = accounts.filter(
                (source) => source?.source_status === "OK"
              );

              if (sourcesWithCredentials.length) hasAnySourcesOK = true;
              if (isRecomended !== -1) {
                sourcesRecommendedWithCredentials = accounts.filter(
                  (source) => source?.source_status === "OK"
                );
                if (sourcesRecommendedWithCredentials.length) {
                  hasAnySourceRecommendedWithCredentials = true;
                }
                sourcesWithOutCredentials = accounts.filter(
                  (source) => source?.source_status === "WITH_OUT_CREDENTIALS"
                );
                if (sourcesWithOutCredentials.length) {
                  hasSourcesWithOutCredentials = true;
                }
                const sourcesOK = accounts.filter((source) => source?.seller);
                if (sourcesOK.length > 0)
                  recommendedIntegrationAvailableOK = true;
                if (sourcesOK.length === accounts.length)
                  allRecommendedIntegrationAvailablOK = true;
                else allRecommendedIntegrationAvailablOK = false;
              }
            }
          });
          let allRecommendedIntegrationAvailable = true;
          let recommendedIntegrationAvailable = false;
          recomended.forEach((rec) => {
            const isIntegrated = sourcesIntegrated.indexOf(rec);
            if (isIntegrated !== -1 && !recommendedIntegrationAvailable)
              recommendedIntegrationAvailable = true;
            if (isIntegrated === -1 && allRecommendedIntegrationAvailable)
              allRecommendedIntegrationAvailable = false;
          });

          setIntegrations(mixData);
          dispatch(loadIntegratedAccounts(mixData));
          dispatch(
            updateIntegrationData({
              allRecommendedIntegrationAvailable,
              recommendedIntegrationAvailable,
              allRecommendedIntegrationAvailablOK,
              recommendedIntegrationAvailableOK,
              hasAnySourcesOK,
              hasSourcesWithOutCredentials,
              sourcesWithOutCredentials,
              hasAnySourceRecommendedWithCredentials,
              sourceErrors: result,
            })
          );
        }
        if (err) setError(err);
        !notRelaod && setLoadingIntegration(false);
      })
      .catch((error) => {
        !notRelaod && setLoadingIntegration(false);
        setError(error);
      });
  };

  return {
    error,
    loadingIntegration,
    integrations,
    reloadIntegrationData: initIntegrationData,
    integratedAccounts,
    sourceError,
  };
}

import React from "react";

import { Grid } from "@mui/material";

import parse from "html-react-parser";
import styles from "../../styles";

export function CustomChoice({
  title,
  subtitle,
  otherChoice,
  onChoiceClick,
  data_cy,
}) {
  return (
    <Grid container direction="column" spacing={2} data-cy={data_cy}>
      <Grid item>
        <h3>{title}</h3>
      </Grid>
      <Grid item>
        <p className="body1" style={{ display: "inline" }}>
          {parse(subtitle)}
        </p>
      </Grid>
      <Grid item>
        {otherChoice && (
          <p style={styles.joinTeam} onClick={onChoiceClick}>
            {otherChoice}
          </p>
        )}
      </Grid>
    </Grid>
  );
}
